import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function HearingLossBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.hearingLoss === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.hearingLoss === YesNo.YES} title="Hearing Loss">
      <YesNoQuestion
        response={responses.hearingLoss}
        text="Do you currently have difficulty hearing?"
      />
      {responses.hearingLoss === YesNo.YES && (
        <YesNoQuestion
          highlight={flags.includes(RespiratorMedicalEvaluationFlag.HEARING_LOSS_SOUND_CRITICAL_ENVIRONMENT)}
          response={responses.hearingLossCanPerformWorkSafely}
          text="Can you hear well enough to do your job safely, talk to coworkers, and hear any sounds that warn you of danger?"
        />
      )}
    </QuestionSection>
  );
}
