import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function ClaustrophobiaBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.claustrophobia === YesNo.YES} title="Claustrophobia">
      <YesNoQuestion
        response={responses.claustrophobia}
        text="Have you ever had claustrophobia (fear of closed-in places)?"
      />
      <YesNoQuestion
        response={responses.claustrophobiaCurrent}
        text="Do you still have claustrophobia?"
      />
      <YesNoQuestion
        response={responses.claustrophobiaMedications}
        text="Do you take any medications for your claustrophobia?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.ADVERSE_EFFECTS_OF_CLAUSTROPHOBIA_MEDS)}
        response={responses.claustrophobiaMedicationsAdverseEffects}
        text="Do your claustrophobia medicines make you feel sleepy, mixed up, tired, or not able to think clearly?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_CLAUSTROPHOBIA_IN_RESPIRATOR)}
        response={responses.claustrophobiaPriorRespiratorUseOk}
        text="Have you been OK with using a respirator in the past, or did it make you feel more scared of tight spaces?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_CLAUSTROPHOBIA_IN_RESPIRATOR)}
        response={responses.claustrophobiaFutureRespiratorUseExpectedOk}
        text="Do you think you can use a respirator, or would it be too hard for you because of your fear of tight spaces?"
      />
    </QuestionSection>
  );
}
