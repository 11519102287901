import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function HypertensionBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.hypertension === YesNo.YES || responses.hypertensionMedications === YesNo.YES} title="Hypertension">
      <YesNoQuestion
        response={responses.hypertension}
        text="Have you ever had high blood pressure (hypertension)?"
      />
      <YesNoQuestion
        response={responses.hypertensionCurrent}
        text="Do you still have high blood pressure (hypertension)?"
      />
      <YesNoQuestion
        response={responses.hypertensionMedications}
        text="Are you taking any medicines for high blood pressure?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.ADVERSE_EFFECTS_OF_HTN_MEDS)}
        response={responses.hypertensionMedicationsAdverseEffects}
        text="Do your medicines make you feel dizzy, faint, sleepy, or confused, or stop you from doing your job safely?"
      />
      {responses.hypertensionTypicalBpSystolic !== null && responses.hypertensionTypicalBpDiastolic !== null && (
        <div>
          <span className={(
            flags.includes(RespiratorMedicalEvaluationFlag.HYPOTENSION)
            || flags.includes(RespiratorMedicalEvaluationFlag.SEVERE_HTN)
            || flags.includes(RespiratorMedicalEvaluationFlag.HTN_MODERATE_EXERTION_SAR)
            || (
              (
                responses.hypertensionTypicalBpSystolic >= 141
                || responses.hypertensionTypicalBpDiastolic >= 91
              )
              && flags.includes(RespiratorMedicalEvaluationFlag.GTE_4_CARDIAC_RISK_FACTORS_MODERATE_EXERTION)
            )
          ) ? 'bg-orange-200' : ''}>What are your usual blood pressure numbers?
          {'  '}<span className="font-bold ml-2">{responses.hypertensionTypicalBpSystolic} / {responses.hypertensionTypicalBpDiastolic}</span></span>
        </div>
      )}
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNMONITORED_HTN)}
        response={responses.hypertensionDoctorVisitLastYear}
        text="Have you visited your heart doctor (cardiologist) or regular doctor about high blood pressure in the last year?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_HTN_ISSUES_AT_WORK)}
        response={responses.hypertensionCausedProblemsAtWork}
        text="Has your blood pressure ever caused problems at work, like making you feel chest pain or dizzy, and led to unsafe situations?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.PHYSICAL_RESTRICTIONS_FOR_HTN)}
        response={responses.hypertensionRestrictions}
        text="Has a doctor told you to avoid intense physical activity because of your high blood pressure or heart problems?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_WORK_ISSUES_FROM_HTN)}
        response={responses.hypertensionWorriesAboutWork}
        text="Do you worry about doing the physical parts of your job because of your high blood pressure?"
      />
      {responses.heartAttack !== YesNo.YES && responses.anginaAssessedByDoctor !== YesNo.NO && responses.anginaCardiac !== YesNo.YES && responses.heartFailure !== YesNo.YES && responses.peripheralEdemaCurrent !== YesNo.YES && (
        <YesNoQuestion
          highlight={responses.heartCondition150MinutesActivityPerWeek === YesNo.NO && flags.includes(RespiratorMedicalEvaluationFlag.GTE_4_CARDIAC_RISK_FACTORS_MODERATE_EXERTION)}
          response={responses.heartCondition150MinutesActivityPerWeek}
          text="Do you get at least 150 minutes of physical activity per week?"
        />
      )}
      {responses.heartAttack !== YesNo.YES && responses.anginaAssessedByDoctor !== YesNo.NO && responses.anginaCardiac !== YesNo.YES && responses.heartFailure !== YesNo.YES && responses.peripheralEdemaCurrent !== YesNo.YES && (
        <YesNoQuestion
          highlight={responses.heartConditionHighCholesterol === YesNo.YES && flags.includes(RespiratorMedicalEvaluationFlag.GTE_4_CARDIAC_RISK_FACTORS_MODERATE_EXERTION)}
          response={responses.heartConditionHighCholesterol}
          text="Do you have high cholesterol?"
        />
      )}
    </QuestionSection>
  );
}
