export enum MedicalSurveillanceEvaluationFlag {
  ALLERGENS_UNAVOIDABLE_AT_WORK = 'ALLERGENS_UNAVOIDABLE_AT_WORK',
  ALLERGY_MEDS_NEEDED_MORE_AT_WORK = 'ALLERGY_MEDS_NEEDED_MORE_AT_WORK',
  ALLERGY_SHOTS_DT_ALLERGENS_AT_WORK = 'ALLERGY_SHOTS_DT_ALLERGENS_AT_WORK',
  ALLERGY_SX_BETTER_AWAY_FROM_WORK = 'ALLERGY_SX_BETTER_AWAY_FROM_WORK',
  ALLERGY_SX_ONSET_P_BEGINNING_JOB = 'ALLERGY_SX_ONSET_P_BEGINNING_JOB',
  ALLERGY_SX_DT_JOB_MATERIALS = 'ALLERGY_SX_DT_JOB_MATERIALS',
  ALLERGY_SX_DT_JOB_TASKS = 'ALLERGY_SX_DT_JOB_TASKS',
  ALLERGY_SX_WORSE_AT_WORK = 'ALLERGY_SX_WORSE_AT_WORK',
  ASTHMA_AGGRAVATORS_AT_WORK = 'ASTHMA_AGGRAVATORS_AT_WORK',
  ASTHMA_SX_BETTER_AWAY_FROM_WORK = 'ASTHMA_SX_BETTER_AWAY_FROM_WORK',
  ASTHMA_SX_ONSET_P_BEGINNING_JOB = 'ASTHMA_SX_ONSET_P_BEGINNING_JOB',
  ASTHMA_SX_DT_JOB_MATERIALS = 'ASTHMA_SX_DT_JOB_MATERIALS',
  ASTHMA_SX_DT_JOB_TASKS = 'ASTHMA_SX_DT_JOB_TASKS',
  ASTHMA_SX_WORSE_AT_WORK = 'ASTHMA_SX_WORSE_AT_WORK',
  COPD_SX_WORSE_AT_WORK = 'COPD_SX_WORSE_AT_WORK',
  CURRENT_ILLNESS = 'CURRENT_ILLNESS',
  EXPOSURE_TO_ALLERGENS_AT_WORK = 'EXPOSURE_TO_ALLERGENS_AT_WORK',
  FREQUENT_ASTHMA_SX = 'FREQUENT_ASTHMA_SX',
  HX_ALLERGIC_REACTION_AT_WORK = 'HX_ALLERGIC_REACTION_AT_WORK',
  HX_ANAPHYLAXIS = 'HX_ANAPHYLAXIS',
  HX_JOB_CHANGE_DT_ALLERGY_SX = 'HX_JOB_CHANGE_DT_ALLERGY_SX',
  HX_JOB_CHANGE_DT_ASTHMA_SX = 'HX_JOB_CHANGE_DT_ASTHMA_SX',
  POORLY_CONTROLLED_ASTHMA = 'POORLY_CONTROLLED_ASTHMA',
  RESCUE_INHALER_NEEDED_MORE_AT_WORK = 'RESCUE_INHALER_NEEDED_MORE_AT_WORK',
  RESPIRATORY_RESCUE_MED_USE_S_SX = 'RESPIRATORY_RESCUE_MED_USE_S_SX',
}

export enum RespiratorMedicalEvaluationFlag {
  ADVERSE_EFFECTS_OF_ALLERGY_MEDS = 'ADVERSE_EFFECTS_OF_ALLERGY_MEDS',
  ADVERSE_EFFECTS_OF_CLAUSTROPHOBIA_MEDS = 'ADVERSE_EFFECTS_OF_CLAUSTROPHOBIA_MEDS',
  ADVERSE_EFFECTS_OF_HTN_MEDS = 'ADVERSE_EFFECTS_OF_HTN_MEDS',
  ADVERSE_EFFECTS_OF_SEIZURE_MEDS = 'ADVERSE_EFFECTS_OF_SEIZURE_MEDS',
  ADVERSE_EFFECTS_OF_UNSPECIFIED_MEDS = 'ADVERSE_EFFECTS_OF_UNSPECIFIED_MEDS',
  AICD_STRONG_MAGNETIC_ENVIRONMENT = 'AICD_STRONG_MAGNETIC_ENVIRONMENT',
  ANXIETY_DT_RESPIRATOR_S_ALTERNATIVE = 'ANXIETY_DT_RESPIRATOR_S_ALTERNATIVE',
  ARRHYTHMIA_C_STRUCTURAL_HEART_DISEASE = 'ARRHYTHMIA_C_STRUCTURAL_HEART_DISEASE',
  ARRHYTHMIA_P_CARDIAC_DEVICE_IMPLANT = 'ARRHYTHMIA_P_CARDIAC_DEVICE_IMPLANT',
  ASBESTOSIS_RESCUE_MEDS_INACCESSIBLE_AT_WORK = 'ASBESTOSIS_RESCUE_MEDS_INACCESSIBLE_AT_WORK',
  ASBESTOSIS_SX_DURING_ADL = 'ASBESTOSIS_SX_DURING_ADL',
  ASTHMA_AGGRAVATORS_AT_WORK = 'ASTHMA_AGGRAVATORS_AT_WORK',
  ASTHMA_RESCUE_MEDS_INACCESSIBLE_AT_WORK = 'ASTHMA_RESCUE_MEDS_INACCESSIBLE_AT_WORK',
  ASTHMA_SX_DURING_ADL = 'ASTHMA_SX_DURING_ADL',
  BREATHING_ISSUES_DT_SMOKING = 'BREATHING_ISSUES_DT_SMOKING',
  CARDIAC_HEARTBURN = 'CARDIAC_HEARTBURN',
  CARDIAC_STRESS_TEST_INDICATING_ARRHYTHMIA_OR_ISCHEMIA = 'CARDIAC_STRESS_TEST_INDICATING_ARRHYTHMIA_OR_ISCHEMIA',
  CHRONIC_BRONCHITIS_RESCUE_MEDS_INACCESSIBLE_AT_WORK = 'CHRONIC_BRONCHITIS_RESCUE_MEDS_INACCESSIBLE_AT_WORK',
  CHRONIC_BRONCHITIS_SX_DURING_ADL = 'CHRONIC_BRONCHITIS_SX_DURING_ADL',
  COLOR_BLINDNESS_ASSISTANCE_UNAVAILABLE = 'COLOR_BLINDNESS_ASSISTANCE_UNAVAILABLE',
  COLOR_BLINDNESS_IMPACTING_SAFETY = 'COLOR_BLINDNESS_IMPACTING_SAFETY',
  COUGH_RESCUE_MEDS_INACCESSIBLE_AT_WORK = 'COUGH_RESCUE_MEDS_INACCESSIBLE_AT_WORK',
  COUGH_RESCUE_MEDS_REQUIRED_HIGH_RISK_ENVIRONMENT = 'COUGH_RESCUE_MEDS_REQUIRED_HIGH_RISK_ENVIRONMENT',
  CURRENT_PTX_SX = 'CURRENT_PTX_SX',
  DERMAL_PROBLEM_DT_RESPIRATOR_S_ALTERNATIVE = 'DERMAL_PROBLEM_DT_RESPIRATOR_S_ALTERNATIVE',
  DIFFICULTY_CARRYING_WEIGHT_SAR = 'DIFFICULTY_CARRYING_WEIGHT_SAR',
  DYSPNEA_RESCUE_MEDS_INACCESSIBLE_AT_WORK = 'DYSPNEA_RESCUE_MEDS_INACCESSIBLE_AT_WORK',
  EMPHYSEMA_RESCUE_MEDS_INACCESSIBLE_AT_WORK = 'EMPHYSEMA_RESCUE_MEDS_INACCESSIBLE_AT_WORK',
  EMPHYSEMA_SX_DURING_ADL = 'EMPHYSEMA_SX_DURING_ADL',
  EMPLOYEE_PREDICTS_ALLERGY_ISSUES_IN_RESPIRATOR = 'EMPLOYEE_PREDICTS_ALLERGY_ISSUES_IN_RESPIRATOR',
  EMPLOYEE_PREDICTS_ASBESTOSIS_ISSUES_IN_RESPIRATOR = 'EMPLOYEE_PREDICTS_ASBESTOSIS_ISSUES_IN_RESPIRATOR',
  EMPLOYEE_PREDICTS_ASTHMA_ISSUES_IN_RESPIRATOR = 'EMPLOYEE_PREDICTS_ASTHMA_ISSUES_IN_RESPIRATOR',
  EMPLOYEE_PREDICTS_CARDIAC_ISSUES_IN_RESPIRATOR = 'EMPLOYEE_PREDICTS_CARDIAC_ISSUES_IN_RESPIRATOR',
  EMPLOYEE_PREDICTS_CHRONIC_BRONCHITIS_ISSUES_IN_RESPIRATOR = 'EMPLOYEE_PREDICTS_CHRONIC_BRONCHITIS_ISSUES_IN_RESPIRATOR',
  EMPLOYEE_PREDICTS_COUGH_ISSUES_IN_RESPIRATOR = 'EMPLOYEE_PREDICTS_COUGH_ISSUES_IN_RESPIRATOR',
  EMPLOYEE_PREDICTS_CLAUSTROPHOBIA_IN_RESPIRATOR = 'EMPLOYEE_PREDICTS_CLAUSTROPHOBIA_IN_RESPIRATOR',
  EMPLOYEE_PREDICTS_DYSPNEA_ISSUES_IN_RESPIRATOR = 'EMPLOYEE_PREDICTS_DYSPNEA_ISSUES_IN_RESPIRATOR',
  EMPLOYEE_PREDICTS_EMPHYSEMA_ISSUES_IN_RESPIRATOR = 'EMPLOYEE_PREDICTS_EMPHYSEMA_ISSUES_IN_RESPIRATOR',
  EMPLOYEE_PREDICTS_FATIGUE_ISSUES_IN_RESPIRATOR = 'EMPLOYEE_PREDICTS_FATIGUE_ISSUES_IN_RESPIRATOR',
  EMPLOYEE_PREDICTS_LUNG_CANCER_ISSUES_IN_RESPIRATOR = 'EMPLOYEE_PREDICTS_LUNG_CANCER_ISSUES_IN_RESPIRATOR',
  EMPLOYEE_PREDICTS_RESPIRATOR_WILL_EXACERBATE_BACK_PAIN = 'EMPLOYEE_PREDICTS_RESPIRATOR_WILL_EXACERBATE_BACK_PAIN',
  EMPLOYEE_PREDICTS_RESPIRATOR_WILL_EXACERBATE_DIFFICULTY_MOVING = 'EMPLOYEE_PREDICTS_RESPIRATOR_WILL_EXACERBATE_DIFFICULTY_MOVING',
  EMPLOYEE_PREDICTS_RESPIRATOR_WILL_EXACERBATE_WEAKNESS = 'EMPLOYEE_PREDICTS_RESPIRATOR_WILL_EXACERBATE_WEAKNESS',
  EMPLOYEE_PREDICTS_SAR_ISSUES_DT_WEAKNESS = 'EMPLOYEE_PREDICTS_SAR_ISSUES_DT_WEAKNESS',
  EMPLOYEE_PREDICTS_SILICOSIS_ISSUES_IN_RESPIRATOR = 'EMPLOYEE_PREDICTS_SILICOSIS_ISSUES_IN_RESPIRATOR',
  EMPLOYEE_PREDICTS_VISION_ISSUES_IN_FULL_FACE_RESPIRATOR = 'EMPLOYEE_PREDICTS_VISION_ISSUES_IN_FULL_FACE_RESPIRATOR',
  EMPLOYEE_PREDICTS_WHEEZING_ISSUES_IN_RESPIRATOR = 'EMPLOYEE_PREDICTS_WHEEZING_ISSUES_IN_RESPIRATOR',
  EMPLOYEE_PREDICTS_WORK_ISSUES_FROM_CHEST_INJURY_OR_SURGERY = 'EMPLOYEE_PREDICTS_WORK_ISSUES_FROM_CHEST_INJURY_OR_SURGERY',
  EMPLOYEE_PREDICTS_WORK_ISSUES_FROM_HTN = 'EMPLOYEE_PREDICTS_WORK_ISSUES_FROM_HTN',
  EMPLOYEE_PREDICTS_WORK_ISSUES_FROM_RIB_FX = 'EMPLOYEE_PREDICTS_WORK_ISSUES_FROM_RIB_FX',
  EXPOSURE_TO_ALLERGENS_AT_WORK = 'EXPOSURE_TO_ALLERGENS_AT_WORK',
  EYE_IRRITATION_DT_RESPIRATOR_S_ALTERNATIVE = 'EYE_IRRITATION_DT_RESPIRATOR_S_ALTERNATIVE',
  EYE_IRRITATION_IMPACTING_SAFETY = 'EYE_IRRITATION_IMPACTING_SAFETY',
  FAILED_CARDIAC_STRESS_TEST = 'FAILED_CARDIAC_STRESS_TEST',
  FATIGUE_IN_RESPIRATOR_C_ADDITIONAL_SX = 'FATIGUE_IN_RESPIRATOR_C_ADDITIONAL_SX',
  FATIGUE_IN_RESPIRATOR_DT_HEALTH_PROBLEM = 'FATIGUE_IN_RESPIRATOR_DT_HEALTH_PROBLEM',
  FATIGUE_IN_RESPIRATOR_IMPACTING_WORK = 'FATIGUE_IN_RESPIRATOR_IMPACTING_WORK',
  FATIGUE_IN_RESPIRATOR_NOT_DT_POOR_FITNESS = 'FATIGUE_IN_RESPIRATOR_NOT_DT_POOR_FITNESS',
  GTE_4_CARDIAC_RISK_FACTORS_MODERATE_EXERTION = 'GTE_4_CARDIAC_RISK_FACTORS_MODERATE_EXERTION',
  HEARING_AIDS_NOT_USABLE_AT_WORK = 'HEARING_AIDS_NOT_USABLE_AT_WORK',
  HEARING_LOSS_SOUND_CRITICAL_ENVIRONMENT = 'HEARING_LOSS_SOUND_CRITICAL_ENVIRONMENT',
  HEARTBURN_S_DX = 'HEARTBURN_S_DX',
  HEART_MURMUR_C_ADDITIONAL_CARDIAC_SX = 'HEART_MURMUR_C_ADDITIONAL_CARDIAC_SX',
  HEART_MURMUR_S_DX = 'HEART_MURMUR_S_DX',
  HIGH_BMI = 'HIGH_BMI',
  HIGH_RISK_ARRHYTHMIA = 'HIGH_RISK_ARRHYTHMIA',
  // HTN_MEDS_S_DX = 'HTN_MEDS_S_DX',  // Deprecated
  HTN_MODERATE_EXERTION_SAR = 'HTN_MODERATE_EXERTION_SAR',
  HX_ALLERGIC_REACTION_AT_WORK = 'HX_ALLERGIC_REACTION_AT_WORK',
  HX_ASBESTOSIS_IMPACTING_WORK = 'HX_ASBESTOSIS_IMPACTING_WORK',
  HX_ASBESTOSIS_ISSUES_IN_RESPIRATOR = 'HX_ASBESTOSIS_TROUBLE_IN_RESPIRATOR',
  HX_ASTHMA_IMPACTING_WORK = 'HX_ASTHMA_IMPACTING_WORK',
  HX_ASTHMA_ISSUES_IN_RESPIRATOR = 'HX_ASTHMA_ISSUES_IN_RESPIRATOR',
  HX_CHRONIC_BRONCHITIS_IMPACTING_WORK = 'HX_CHRONIC_BRONCHITIS_IMPACTING_WORK',
  HX_CHRONIC_BRONCHITIS_ISSUES_IN_RESPIRATOR = 'HX_CHRONIC_BRONCHITIS_ISSUES_IN_RESPIRATOR',
  HX_CLAUSTROPHOBIA_IN_RESPIRATOR = 'HX_CLAUSTROPHOBIA_IN_RESPIRATOR',
  HX_CVA_HIGH_RISK_ENVIRONMENT = 'HX_CVA_HIGH_RISK_ENVIRONMENT',
  HX_CVA_MODERATE_EXERTION = 'HX_CVA_MODERATE_EXERTION',
  HX_DYSPNEA_IMPACTING_WORK = 'HX_DYSPNEA_IMPACTING_WORK',
  HX_DYSPNEA_ISSUES_IN_RESPIRATOR = 'HX_DYSPNEA_ISSUES_IN_RESPIRATOR',
  HX_EMPHYSEMA_IMPACTING_WORK = 'HX_EMPHYSEMA_IMPACTING_WORK',
  HX_EMPHYSEMA_ISSUES_IN_RESPIRATOR = 'HX_EMPHYSEMA_ISSUES_IN_RESPIRATOR',
  HX_HTN_ISSUES_AT_WORK = 'HX_HTN_ISSUES_AT_WORK',
  HX_HYPOGLYCEMIC_SYNCOPE_AT_WORK = 'HX_HYPOGLYCEMIC_SYNCOPE_AT_WORK',
  HX_LUNG_CANCER_ISSUES_IN_RESPIRATOR_SINCE_REMISSION = 'HX_LUNG_CANCER_ISSUES_IN_RESPIRATOR_SINCE_REMISSION',
  HX_SAFETY_ISSUES_IN_RESPIRATOR_DT_ANXIETY = 'HX_SAFETY_ISSUES_IN_RESPIRATOR_DT_ANXIETY',
  HX_SEIZURES_AT_WORK = 'HX_SEIZURES_AT_WORK',
  HX_SILICOSIS_IMPACTING_WORK = 'HX_SILICOSIS_IMPACTING_WORK',
  HX_SILICOSIS_ISSUES_IN_RESPIRATOR = 'HX_SILICOSIS_ISSUES_IN_RESPIRATOR',
  HX_TIA_AFTER_CVA = 'HX_TIA_AFTER_CVA',
  HX_VISION_ISSUES_IN_FULL_FACE_RESPIRATOR = 'HX_VISION_ISSUES_IN_FULL_FACE_RESPIRATOR',
  HX_WEAKNESS_ISSUES_IN_SAR = 'HX_WEAKNESS_ISSUES_IN_SAR',
  HX_WHEEZING_IMPACTING_WORK = 'HX_WHEEZING_IMPACTING_WORK',
  HX_WHEEZING_ISSUES_IN_RESPIRATOR = 'HX_WHEEZING_ISSUES_IN_RESPIRATOR',
  HYPOSMIA_SMELL_CRITICAL_ENVIRONMENT = 'HYPOSMIA_SMELL_CRITICAL_ENVIRONMENT',
  HYPOTENSION = 'HYPOTENSION',
  IMPLANTABLE_CARDIAC_DEVICE_IDLH = 'IMPLANTABLE_CARDIAC_DEVICE_IDLH',
  ISCHEMIC_HEART_CONDITION_C_ADDITIONAL_SX = 'ISCHEMIC_HEART_CONDITION_C_ADDITIONAL_SX',
  // LASTING_COMPLICATIONS_FROM_EAR_INJURY = 'LASTING_COMPLICATIONS_FROM_EAR_INJURY',  // Deprecated
  LASTING_DEFICITS_FROM_CVA = 'LASTING_DEFICITS_FROM_CVA',
  LUNG_CANCER_NOT_IN_REMISSION_FIVE_YEARS = 'LUNG_CANCER_NOT_IN_REMISSION_FIVE_YEARS',
  MARKED_SX_OF_CHF = 'MARKED_SX_OF_CHF',
  MEDIUM_RISK_PTX_HX_HIGH_RISK_ENVIRONMENT = 'MEDIUM_RISK_PTX_HX_HIGH_RISK_ENVIRONMENT',
  MILD_SX_OF_CHF_HIGH_RISK_ENVIRONMENT = 'MILD_SX_OF_CHF_HIGH_RISK_ENVIRONMENT',
  MI_S_CARDIAC_STRESS_TEST = 'MI_S_CARDIAC_STRESS_TEST',
  MODERATE_DIFFICULTY_MOVING_HEAVY_EXERTION = 'MODERATE_DIFFICULTY_MOVING_HEAVY_EXERTION',
  MODERATE_DYSPNEA = 'MODERATE_DYSPNEA',
  MULTIPLE_ARRHYTHMIAS = 'MULTIPLE_ARRHYTHMIAS',
  NO_PROVEN_SAR_USE_SINCE_LUNG_CANCER_REMISSION = 'NO_PROVEN_SAR_USE_SINCE_LUNG_CANCER_REMISSION',
  PACEMAKER_COLLISION_PRONE_ENVIRONMENT = 'PACEMAKER_COLLISION_PRONE_ENVIRONMENT',
  PHYSICAL_RESTRICTIONS_FOR_HTN = 'PHYSICAL_RESTRICTIONS_FOR_HTN',
  PHYSICAL_RESTRICTIONS_FOR_MI = 'PHYSICAL_RESTRICTIONS_FOR_MI',
  PLEURITIC_CHEST_PAIN = 'PLEURITIC_CHEST_PAIN',
  POORLY_CONTROLLED_ASTHMA = 'POORLY_CONTROLLED_ASTHMA',
  POSSIBILITY_OF_ACTIVE_TB = 'POSSIBILITY_OF_ACTIVE_TB',
  POSSIBILITY_OF_ANXIETY_TRIGGERS_AT_WORK = 'POSSIBILITY_OF_ANXIETY_TRIGGERS_AT_WORK',
  POSSIBILITY_OF_DERMAL_PROBLEM_AGGRAVATORS_AT_WORK = 'POSSIBILITY_OF_DERMAL_PROBLEM_AGGRAVATORS_AT_WORK',
  POSSIBILITY_OF_DERMAL_PROBLEM_AGGRAVATORS_AT_WORK_PPE_UNAVAILABLE = 'POSSIBILITY_OF_DERMAL_PROBLEM_AGGRAVATORS_AT_WORK_PPE_UNAVAILABLE',
  POSSIBILITY_OF_EYE_IRRITATION_AGGRAVATORS_AT_WORK = 'POSSIBILITY_OF_EYE_IRRITATION_AGGRAVATORS_AT_WORK',
  POSSIBILITY_OF_HYPOGLYCEMIC_SYNCOPE_S_SAFE_REMOVAL = 'POSSIBILITY_OF_HYPOGLYCEMIC_SYNCOPE_S_SAFE_REMOVAL',
  POSSIBILITY_OF_SEIZURES_S_SAFE_REMOVAL = 'POSSIBILITY_OF_SEIZURES_S_SAFE_REMOVAL',
  POSSIBILITY_OF_UNTREATABLE_ALLERGIES_AT_WORK = 'POSSIBILITY_OF_UNTREATABLE_ALLERGIES_AT_WORK',
  RECENT_AND_MULTIPLE_PNEUMOTHORACES = 'RECENT_AND_MULTIPLE_PNEUMOTHORACES',
  RECENT_AND_MULTIPLE_SEIZURES = 'RECENT_AND_MULTIPLE_SEIZURES',
  RECENT_ANGINA = 'RECENT_ANGINA',
  RECENT_CORONARY_ARTERY_PROCEDURE = 'RECENT_CORONARY_ARTERY_PROCEDURE',
  RECENT_CVA = 'RECENT_CVA',
  RECENT_HOSPITAL_STAY_FOR_ASBESTOSIS = 'RECENT_HOSPITAL_STAY_FOR_ASBESTOSIS',
  RECENT_HOSPITAL_STAY_FOR_ASTHMA = 'RECENT_HOSPITAL_STAY_FOR_ASTHMA',
  RECENT_HOSPITAL_STAY_FOR_CARDIAC_PROBLEM = 'RECENT_HOSPITAL_STAY_FOR_CARDIAC_PROBLEM',
  RECENT_HOSPITAL_STAY_FOR_CHRONIC_BRONCHITIS = 'RECENT_HOSPITAL_STAY_FOR_CHRONIC_BRONCHITIS',
  RECENT_HOSPITAL_STAY_FOR_DYSPNEA = 'RECENT_HOSPITAL_STAY_FOR_DYSPNEA',
  RECENT_HOSPITAL_STAY_FOR_EMPHYSEMA = 'RECENT_HOSPITAL_STAY_FOR_EMPHYSEMA',
  RECENT_HOSPITAL_STAY_FOR_SILICOSIS = 'RECENT_HOSPITAL_STAY_FOR_SILICOSIS',
  RECENT_HOSPITAL_STAY_FOR_WHEEZING = 'RECENT_HOSPITAL_STAY_FOR_WHEEZING',
  RECENT_MI = 'RECENT_MI',
  RECENT_SEIZURE_HIGH_RISK_ENVIRONMENT = 'RECENT_SEIZURE_HIGH_RISK_ENVIRONMENT',
  RECENT_UNEXPLAINED_PTX = 'RECENT_UNEXPLAINED_PTX',
  RECENT_USE_OF_MEDS_FOR_ACUTE_ANGINA = 'RECENT_USE_OF_MEDS_FOR_ACUTE_ANGINA',
  SEIZURE_MEDS_S_DX = 'SEIZURE_MEDS_S_DX',
  SERIOUS_HEART_MURMUR = 'SERIOUS_HEART_MURMUR',
  SEVERE_ASBESTOSIS = 'SEVERE_ASBESTOSIS',
  SEVERE_ASTHMA = 'SEVERE_ASTHMA',
  SEVERE_CHRONIC_BRONCHITIS = 'SEVERE_CHRONIC_BRONCHITIS',
  SEVERE_COUGH = 'SEVERE_COUGH',
  SEVERE_COUGHING_FITS = 'SEVERE_COUGHING_FITS',
  SEVERE_DIFFICULTY_MOVING_SAR = 'SEVERE_DIFFICULTY_MOVING_SAR',
  SEVERE_DYSPNEA = 'SEVERE_DYSPNEA',
  SEVERE_EMPHYSEMA = 'SEVERE_EMPHYSEMA',
  SEVERE_HTN = 'SEVERE_HTN',
  SEVERE_SILICOSIS = 'SEVERE_SILICOSIS',
  SEVERE_WHEEZING = 'SEVERE_WHEEZING',
  SILICOSIS_RESCUE_MEDS_INACCESSIBLE_AT_WORK = 'SILICOSIS_RESCUE_MEDS_INACCESSIBLE_AT_WORK',
  SILICOSIS_SX_DURING_ADL = 'SILICOSIS_SX_DURING_ADL',
  SMOKER_C_MEDIUM_RISK_PTX_HX = 'SMOKER_C_MEDIUM_RISK_PTX_HX',
  UNASSESSED_ANGINA = 'UNASSESSED_ANGINA',
  UNCONTROLLED_ANGINA = 'UNCONTROLLED_ANGINA',
  UNCONTROLLED_SEIZURES = 'UNCONTROLLED_SEIZURES',
  UNMONITORED_HTN = 'UNMONITORED_HTN',
  UNRESOLVED_BACK_INJURY_SAR = 'UNRESOLVED_BACK_INJURY_SAR',
  UNRESOLVED_CHEST_INJURY_OR_SURGERY_C_DYSPNEA = 'UNRESOLVED_CHEST_INJURY_OR_SURGERY_C_DYSPNEA',
  UNRESOLVED_PNEUMONIA = 'UNRESOLVED_PNEUMONIA',
  UNRESOLVED_RIB_FX_C_DYSPNEA = 'UNRESOLVED_RIB_FX_C_DYSPNEA',
  UNRESOLVED_TB = 'UNRESOLVED_TB',
  UNSPECIFIED_HEARING_PROBLEM = 'UNSPECIFIED_HEARING_PROBLEM',
  UNSPECIFIED_HEART_CONDITION = 'UNSPECIFIED_HEART_CONDITION',
  UNSPECIFIED_HEART_SX = 'UNSPECIFIED_HEART_SX',
  UNSPECIFIED_IMPLANTABLE_CARDIAC_DEVICE = 'UNSPECIFIED_IMPLANTABLE_CARDIAC_DEVICE',
  UNSPECIFIED_LUNG_CONDITION = 'UNSPECIFIED_LUNG_CONDITION',
  UNSPECIFIED_LUNG_SX = 'UNSPECIFIED_LUNG_SX',
  UNSPECIFIED_MUSCULOSKELETAL_PROBLEM = 'UNSPECIFIED_MUSCULOSKELETAL_PROBLEM',
  UNSPECIFIED_PROBLEM_IN_RESPIRATOR = 'UNSPECIFIED_PROBLEM_IN_RESPIRATOR',
  UNSPECIFIED_VISION_PROBLEM = 'UNSPECIFIED_VISION_PROBLEM',
  UNSTABLE_ANGINA = 'UNSTABLE_ANGINA',
  UNSTABLE_ASBESTOSIS = 'UNSTABLE_ASBESTOSIS',
  UNSTABLE_ASTHMA = 'UNSTABLE_ASTHMA',
  UNSTABLE_CHRONIC_BRONCHITIS = 'UNSTABLE_CHRONIC_BRONCHITIS',
  UNSTABLE_DYSPNEA = 'UNSTABLE_DYSPNEA',
  UNSTABLE_EMPHYSEMA = 'UNSTABLE_EMPHYSEMA',
  UNSTABLE_SILICOSIS = 'UNSTABLE_SILICOSIS',
  UNSTABLE_WEAKNESS = 'UNSTABLE_WEAKNESS',
  UNSTABLE_WHEEZING = 'UNSTABLE_WHEEZING',
  VISION_CORRECTION_NOT_USABLE_AT_WORK = 'VISION_CORRECTION_NOT_USABLE_AT_WORK',
  VISION_LOSS_IMPACTING_WORK = 'VISION_LOSS_IMPACTING_WORK',
  WEAKNESS_IMPACTING_SAFE_REMOVAL = 'WEAKNESS_IMPACTING_SAFE_REMOVAL',
  WEAKNESS_IMPACTING_WORK = 'WEAKNESS_IMPACTING_WORK',
  WHEEZING_AGGRAVATORS_AT_WORK = 'WHEEZING_AGGRAVATORS_AT_WORK',
  WHEEZING_IMPACTING_WORK = 'WHEEZING_IMPACTING_WORK',
  WHEEZING_RESCUE_MEDS_INACCESSIBLE_AT_WORK = 'WHEEZING_RESCUE_MEDS_INACCESSIBLE_AT_WORK',
  WORK_ACCOMMODATIONS_FOR_ANGINA = 'WORK_ACCOMMODATIONS_FOR_ANGINA',
  WORK_ACCOMMODATIONS_FOR_ASBESTOSIS = 'WORK_ACCOMMODATIONS_FOR_ASBESTOSIS',
  WORK_ACCOMMODATIONS_FOR_ARRHYTHMIA = 'WORK_ACCOMMODATIONS_FOR_ARRHYTHMIA',
  WORK_ACCOMMODATIONS_FOR_ASTHMA = 'WORK_ACCOMMODATIONS_FOR_ASTHMA',
  WORK_ACCOMMODATIONS_FOR_CHF = 'WORK_ACCOMMODATIONS_FOR_CHF',
  WORK_ACCOMMODATIONS_FOR_CHRONIC_BRONCHITIS = 'WORK_ACCOMMODATIONS_FOR_CHRONIC_BRONCHITIS',
  WORK_ACCOMMODATIONS_FOR_CVA = 'WORK_ACCOMMODATIONS_FOR_CVA',
  WORK_ACCOMMODATIONS_FOR_DIABETES = 'WORK_ACCOMMODATIONS_FOR_DIABETES',
  WORK_ACCOMMODATIONS_FOR_DYSPNEA = 'WORK_ACCOMMODATIONS_FOR_DYSPNEA',
  WORK_ACCOMMODATIONS_FOR_EMPHYSEMA = 'WORK_ACCOMMODATIONS_FOR_EMPHYSEMA',
  WORK_ACCOMMODATIONS_FOR_LUNG_CANCER = 'WORK_ACCOMMODATIONS_FOR_LUNG_CANCER',
  WORK_ACCOMMODATIONS_FOR_MI = 'WORK_ACCOMMODATIONS_FOR_MI',
  WORK_ACCOMMODATIONS_FOR_SEIZURES = 'WORK_ACCOMMODATIONS_FOR_SEIZURES',
  WORK_ACCOMMODATIONS_FOR_SILICOSIS = 'WORK_ACCOMMODATIONS_FOR_SILICOSIS',
  WORK_ACCOMMODATIONS_FOR_WEAKNESS = 'WORK_ACCOMMODATIONS_FOR_WEAKNESS',
  WORK_ACCOMMODATIONS_FOR_WHEEZING = 'WORK_ACCOMMODATIONS_FOR_WHEEZING',
  WORK_ALONE_C_SEIZURES = 'WORK_ALONE_C_SEIZURES',
  // WORK_IN_CONFINED_SPACES_C_CLAUSTROPHOBIA = 'WORK_IN_CONFINED_SPACES_C_CLAUSTROPHOBIA',  // Deprecated
}

export const mseFlagText = {
  [MedicalSurveillanceEvaluationFlag.ALLERGENS_UNAVOIDABLE_AT_WORK]: 'Allergens unavoidable at work',
  [MedicalSurveillanceEvaluationFlag.ALLERGY_MEDS_NEEDED_MORE_AT_WORK]: 'Allergy meds needed more at work',
  [MedicalSurveillanceEvaluationFlag.ALLERGY_SHOTS_DT_ALLERGENS_AT_WORK]: 'Allergy shots d/t allergens at work',
  [MedicalSurveillanceEvaluationFlag.ALLERGY_SX_BETTER_AWAY_FROM_WORK]: 'Allergy Sx improve away from work',
  [MedicalSurveillanceEvaluationFlag.ALLERGY_SX_ONSET_P_BEGINNING_JOB]: 'Allergy Sx onset after beginning job',
  [MedicalSurveillanceEvaluationFlag.ALLERGY_SX_DT_JOB_MATERIALS]: 'Allergy Sx worsened by materials at work',
  [MedicalSurveillanceEvaluationFlag.ALLERGY_SX_DT_JOB_TASKS]: 'Allergy Sx worsened by job tasks',
  [MedicalSurveillanceEvaluationFlag.ALLERGY_SX_WORSE_AT_WORK]: 'Allergy Sx worse at work',
  [MedicalSurveillanceEvaluationFlag.ASTHMA_AGGRAVATORS_AT_WORK]: 'Asthma aggravators at work',
  [MedicalSurveillanceEvaluationFlag.ASTHMA_SX_BETTER_AWAY_FROM_WORK]: 'Asthma Sx improve away from work',
  [MedicalSurveillanceEvaluationFlag.ASTHMA_SX_ONSET_P_BEGINNING_JOB]: 'Asthma Sx onset after beginning job',
  [MedicalSurveillanceEvaluationFlag.ASTHMA_SX_DT_JOB_MATERIALS]: 'Asthma Sx worsened by materials at work',
  [MedicalSurveillanceEvaluationFlag.ASTHMA_SX_DT_JOB_TASKS]: 'Asthma Sx worsened by job tasks',
  [MedicalSurveillanceEvaluationFlag.ASTHMA_SX_WORSE_AT_WORK]: 'Asthma Sx worse at work',
  [MedicalSurveillanceEvaluationFlag.COPD_SX_WORSE_AT_WORK]: 'COPD Sx worse at work',
  [MedicalSurveillanceEvaluationFlag.CURRENT_ILLNESS]: 'Current illness',
  [MedicalSurveillanceEvaluationFlag.EXPOSURE_TO_ALLERGENS_AT_WORK]: 'Exposure to allergens at work',
  [MedicalSurveillanceEvaluationFlag.FREQUENT_ASTHMA_SX]: 'Frequent asthma Sx',
  [MedicalSurveillanceEvaluationFlag.HX_ALLERGIC_REACTION_AT_WORK]: 'Hx of allergic reaction at work',
  [MedicalSurveillanceEvaluationFlag.HX_ANAPHYLAXIS]: 'Hx of anaphylaxis',
  [MedicalSurveillanceEvaluationFlag.HX_JOB_CHANGE_DT_ALLERGY_SX]: 'Hx of job change d/t allergy Sx',
  [MedicalSurveillanceEvaluationFlag.HX_JOB_CHANGE_DT_ASTHMA_SX]: 'Hx of job change d/t asthma Sx',
  [MedicalSurveillanceEvaluationFlag.POORLY_CONTROLLED_ASTHMA]: 'Poorly controlled asthma',
  [MedicalSurveillanceEvaluationFlag.RESCUE_INHALER_NEEDED_MORE_AT_WORK]: 'Rescue inhaler needed more at work',
  [MedicalSurveillanceEvaluationFlag.RESPIRATORY_RESCUE_MED_USE_S_SX]: 'Respiratory rescue med use without respiratory Sx',
};

export const rmeFlagText = {
  [RespiratorMedicalEvaluationFlag.ADVERSE_EFFECTS_OF_ALLERGY_MEDS]: 'Adverse effects of allergy meds',
  [RespiratorMedicalEvaluationFlag.ADVERSE_EFFECTS_OF_CLAUSTROPHOBIA_MEDS]: 'Adverse effects of claustrophobia meds',
  [RespiratorMedicalEvaluationFlag.ADVERSE_EFFECTS_OF_HTN_MEDS]: 'Adverse effects of HTN meds',
  [RespiratorMedicalEvaluationFlag.ADVERSE_EFFECTS_OF_SEIZURE_MEDS]: 'Adverse effects of seizure meds',
  [RespiratorMedicalEvaluationFlag.ADVERSE_EFFECTS_OF_UNSPECIFIED_MEDS]: 'Adverse effects of misc lung or heart meds',
  [RespiratorMedicalEvaluationFlag.AICD_STRONG_MAGNETIC_ENVIRONMENT]: 'AICD / strong magnetic environment',
  [RespiratorMedicalEvaluationFlag.ANXIETY_DT_RESPIRATOR_S_ALTERNATIVE]: 'Anxiety d/t respirator / no alternative',
  [RespiratorMedicalEvaluationFlag.ASBESTOSIS_RESCUE_MEDS_INACCESSIBLE_AT_WORK]: 'Asbestosis rescue meds inaccessible at work',
  [RespiratorMedicalEvaluationFlag.ASBESTOSIS_SX_DURING_ADL]: 'Asbestosis Sx during ADL',
  [RespiratorMedicalEvaluationFlag.ARRHYTHMIA_C_STRUCTURAL_HEART_DISEASE]: 'Arrhythmia + structural heart disease',
  [RespiratorMedicalEvaluationFlag.ARRHYTHMIA_P_CARDIAC_DEVICE_IMPLANT]: 'Arrhythmia after cardiac device implant',
  [RespiratorMedicalEvaluationFlag.ASTHMA_AGGRAVATORS_AT_WORK]: 'Asthma aggravators at work',
  [RespiratorMedicalEvaluationFlag.ASTHMA_RESCUE_MEDS_INACCESSIBLE_AT_WORK]: 'Asthma rescue meds inaccessible at work',
  [RespiratorMedicalEvaluationFlag.ASTHMA_SX_DURING_ADL]: 'Asthma Sx during ADL',
  [RespiratorMedicalEvaluationFlag.BREATHING_ISSUES_DT_SMOKING]: 'Breathing issues d/t smoking',
  [RespiratorMedicalEvaluationFlag.CARDIAC_HEARTBURN]: 'Cardiac-related heartburn',
  [RespiratorMedicalEvaluationFlag.CARDIAC_STRESS_TEST_INDICATING_ARRHYTHMIA_OR_ISCHEMIA]: 'Cardiac stress test indicating arrhythmia or ischemia',
  [RespiratorMedicalEvaluationFlag.CHRONIC_BRONCHITIS_RESCUE_MEDS_INACCESSIBLE_AT_WORK]: 'Chronic bronchitis rescue meds inaccessible at work',
  [RespiratorMedicalEvaluationFlag.CHRONIC_BRONCHITIS_SX_DURING_ADL]: 'Chronic bronchitis Sx during ADL',
  [RespiratorMedicalEvaluationFlag.COLOR_BLINDNESS_ASSISTANCE_UNAVAILABLE]: 'Color blindness assistance unavailable',
  [RespiratorMedicalEvaluationFlag.COLOR_BLINDNESS_IMPACTING_SAFETY]: 'Color blindness that impacts safety',
  [RespiratorMedicalEvaluationFlag.COUGH_RESCUE_MEDS_INACCESSIBLE_AT_WORK]: 'Cough rescue meds inaccessible at work',
  [RespiratorMedicalEvaluationFlag.COUGH_RESCUE_MEDS_REQUIRED_HIGH_RISK_ENVIRONMENT]: 'Cough rescue meds required / high-risk environment',
  [RespiratorMedicalEvaluationFlag.CURRENT_PTX_SX]: 'Current PTX Sx',
  [RespiratorMedicalEvaluationFlag.DERMAL_PROBLEM_DT_RESPIRATOR_S_ALTERNATIVE]: 'Dermal problem d/t respirator / unable to use alternative',
  [RespiratorMedicalEvaluationFlag.DIFFICULTY_CARRYING_WEIGHT_SAR]: 'Difficulty carrying weight / SAR/SCBA',
  [RespiratorMedicalEvaluationFlag.DYSPNEA_RESCUE_MEDS_INACCESSIBLE_AT_WORK]: 'Dyspnea rescue meds inaccessible at work',
  [RespiratorMedicalEvaluationFlag.EMPHYSEMA_RESCUE_MEDS_INACCESSIBLE_AT_WORK]: 'Emphysema rescue meds inaccessible at work',
  [RespiratorMedicalEvaluationFlag.EMPHYSEMA_SX_DURING_ADL]: 'Emphysema Sx during ADL',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_ALLERGY_ISSUES_IN_RESPIRATOR]: 'Employee predicts respirator issues d/t allergies',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_ASBESTOSIS_ISSUES_IN_RESPIRATOR]: 'Employee predicts respirator issues d/t asbestosis',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_ASTHMA_ISSUES_IN_RESPIRATOR]: 'Employee predicts respirator issues d/t asthma',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_CARDIAC_ISSUES_IN_RESPIRATOR]: 'Employee predicts respirator issues d/t cardiac condition',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_CHRONIC_BRONCHITIS_ISSUES_IN_RESPIRATOR]: 'Employee predicts respirator issues d/t chronic bronchitis',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_CLAUSTROPHOBIA_IN_RESPIRATOR]: 'Employee predicts respirator issues d/t claustrophobia',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_COUGH_ISSUES_IN_RESPIRATOR]: 'Employee predicts respirator issues d/t cough',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_DYSPNEA_ISSUES_IN_RESPIRATOR]: 'Employee predicts respirator issues d/t dyspnea',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_EMPHYSEMA_ISSUES_IN_RESPIRATOR]: 'Employee predicts respirator issues d/t emphysema',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_FATIGUE_ISSUES_IN_RESPIRATOR]: 'Employee predicts respirator issues d/t fatigue',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_LUNG_CANCER_ISSUES_IN_RESPIRATOR]: 'Employee predicts respirator issues d/t lung cancer',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_RESPIRATOR_WILL_EXACERBATE_BACK_PAIN]: 'Employee predicts respirator will exacerbate back pain',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_RESPIRATOR_WILL_EXACERBATE_DIFFICULTY_MOVING]: 'Employee predicts respirator will exacerbate difficulty moving',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_RESPIRATOR_WILL_EXACERBATE_WEAKNESS]: 'Employee predicts respirator will exacerbate weakness',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_SAR_ISSUES_DT_WEAKNESS]: 'Employee predicts SAR/SCBA issues d/t weakness',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_SILICOSIS_ISSUES_IN_RESPIRATOR]: 'Employee predicts respirator issues d/t silicosis',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_VISION_ISSUES_IN_FULL_FACE_RESPIRATOR]: 'Employee predicts full-face respirator issues d/t vision issues',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_WHEEZING_ISSUES_IN_RESPIRATOR]: 'Employee predicts respirator issues d/t wheezing',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_WORK_ISSUES_FROM_CHEST_INJURY_OR_SURGERY]: 'Employee predicts work issues d/t chest injury or surgery',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_WORK_ISSUES_FROM_HTN]: 'Employee predicts work issues d/t HTN',
  [RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_WORK_ISSUES_FROM_RIB_FX]: 'Employee predicts work issues d/t rib Fx',
  [RespiratorMedicalEvaluationFlag.EXPOSURE_TO_ALLERGENS_AT_WORK]: 'Exposure to allergens at work',
  [RespiratorMedicalEvaluationFlag.EYE_IRRITATION_DT_RESPIRATOR_S_ALTERNATIVE]: 'Eye irritation d/t respirator / unable to use alternative',
  [RespiratorMedicalEvaluationFlag.EYE_IRRITATION_IMPACTING_SAFETY]: 'Eye irritation that impacts safety',
  [RespiratorMedicalEvaluationFlag.FAILED_CARDIAC_STRESS_TEST]: 'Failed cardiac stress test',
  [RespiratorMedicalEvaluationFlag.FATIGUE_IN_RESPIRATOR_C_ADDITIONAL_SX]: 'Fatigue in respirator + additional Sx',
  [RespiratorMedicalEvaluationFlag.FATIGUE_IN_RESPIRATOR_DT_HEALTH_PROBLEM]: 'Fatigue in respirator d/t health problem',
  [RespiratorMedicalEvaluationFlag.FATIGUE_IN_RESPIRATOR_IMPACTING_WORK]: 'Fatigue in respirator impacting work',
  [RespiratorMedicalEvaluationFlag.FATIGUE_IN_RESPIRATOR_NOT_DT_POOR_FITNESS]: 'Fatigue in respirator not d/t poor fitness',
  [RespiratorMedicalEvaluationFlag.GTE_4_CARDIAC_RISK_FACTORS_MODERATE_EXERTION]: '\u22654 cardiac risk factors / \u2265 moderate exertion',
  [RespiratorMedicalEvaluationFlag.HEARING_AIDS_NOT_USABLE_AT_WORK]: 'Hearing aids not usable at work',
  [RespiratorMedicalEvaluationFlag.HEARING_LOSS_SOUND_CRITICAL_ENVIRONMENT]: 'Hearing loss / sound-critical environment',
  [RespiratorMedicalEvaluationFlag.HEARTBURN_S_DX]: 'Heartburn without Dx',
  [RespiratorMedicalEvaluationFlag.HEART_MURMUR_C_ADDITIONAL_CARDIAC_SX]: 'Heart murmur + additional cardiac Sx',
  [RespiratorMedicalEvaluationFlag.HEART_MURMUR_S_DX]: 'Heart murmur without Dx',
  [RespiratorMedicalEvaluationFlag.HIGH_BMI]: 'High BMI',
  [RespiratorMedicalEvaluationFlag.HIGH_RISK_ARRHYTHMIA]: 'High-risk arrhythmia',
  // [RespiratorMedicalEvaluationFlag.HTN_MEDS_S_DX]: 'HTN meds without Dx',  // Deprecated
  [RespiratorMedicalEvaluationFlag.HTN_MODERATE_EXERTION_SAR]: 'HTN / \u2265 moderate exertion / SAR/SCBA',
  [RespiratorMedicalEvaluationFlag.HX_ALLERGIC_REACTION_AT_WORK]: 'Hx of allergic reaction at work',
  [RespiratorMedicalEvaluationFlag.HX_ASBESTOSIS_IMPACTING_WORK]: 'Hx of asbestosis impacting work',
  [RespiratorMedicalEvaluationFlag.HX_ASBESTOSIS_ISSUES_IN_RESPIRATOR]: 'Hx of asbestosis issues in respirator',
  [RespiratorMedicalEvaluationFlag.HX_ASTHMA_IMPACTING_WORK]: 'Hx of asthma impacting work',
  [RespiratorMedicalEvaluationFlag.HX_ASTHMA_ISSUES_IN_RESPIRATOR]: 'Hx of asthma issues in respirator',
  [RespiratorMedicalEvaluationFlag.HX_CHRONIC_BRONCHITIS_IMPACTING_WORK]: 'Hx of chronic bronchitis impacting work',
  [RespiratorMedicalEvaluationFlag.HX_CHRONIC_BRONCHITIS_ISSUES_IN_RESPIRATOR]: 'Hx of chronic bronchitis issues in respirator',
  [RespiratorMedicalEvaluationFlag.HX_CLAUSTROPHOBIA_IN_RESPIRATOR]: 'Hx of claustrophobia in respirator',
  [RespiratorMedicalEvaluationFlag.HX_CVA_HIGH_RISK_ENVIRONMENT]: 'Hx of CVA / high-risk environment',
  [RespiratorMedicalEvaluationFlag.HX_CVA_MODERATE_EXERTION]: 'Hx of CVA / \u2265 moderate exertion',
  [RespiratorMedicalEvaluationFlag.HX_DYSPNEA_IMPACTING_WORK]: 'Hx of dyspnea impacting work',
  [RespiratorMedicalEvaluationFlag.HX_DYSPNEA_ISSUES_IN_RESPIRATOR]: 'Hx of dyspnea issues in respirator',
  [RespiratorMedicalEvaluationFlag.HX_EMPHYSEMA_IMPACTING_WORK]: 'Hx of emphysema impacting work',
  [RespiratorMedicalEvaluationFlag.HX_EMPHYSEMA_ISSUES_IN_RESPIRATOR]: 'Hx of emphysema issues in respirator',
  [RespiratorMedicalEvaluationFlag.HX_HTN_ISSUES_AT_WORK]: 'Hx of HTN issues at work',
  [RespiratorMedicalEvaluationFlag.HX_HYPOGLYCEMIC_SYNCOPE_AT_WORK]: 'Hx of hypoglycemic syncope at work',
  [RespiratorMedicalEvaluationFlag.HX_LUNG_CANCER_ISSUES_IN_RESPIRATOR_SINCE_REMISSION]: 'Hx of lung cancer issues in respirator since remission',
  [RespiratorMedicalEvaluationFlag.HX_SAFETY_ISSUES_IN_RESPIRATOR_DT_ANXIETY]: 'Hx of safety issues in respirator d/t anxiety',
  [RespiratorMedicalEvaluationFlag.HX_SEIZURES_AT_WORK]: 'Hx of seizures at work',
  [RespiratorMedicalEvaluationFlag.HX_SILICOSIS_IMPACTING_WORK]: 'Hx of silicosis impacting work',
  [RespiratorMedicalEvaluationFlag.HX_SILICOSIS_ISSUES_IN_RESPIRATOR]: 'Hx of silicosis issues in respirator',
  [RespiratorMedicalEvaluationFlag.HX_TIA_AFTER_CVA]: 'Hx of TIA(s) subsequent to CVA',
  [RespiratorMedicalEvaluationFlag.HX_VISION_ISSUES_IN_FULL_FACE_RESPIRATOR]: 'Hx of vision issues in full-face respirator',
  [RespiratorMedicalEvaluationFlag.HX_WEAKNESS_ISSUES_IN_SAR]: 'Hx of weakness issues in SAR/SCBA',
  [RespiratorMedicalEvaluationFlag.HX_WHEEZING_IMPACTING_WORK]: 'Hx of wheezing impacting work',
  [RespiratorMedicalEvaluationFlag.HX_WHEEZING_ISSUES_IN_RESPIRATOR]: 'Hx of wheezing issues in respirator',
  [RespiratorMedicalEvaluationFlag.HYPOSMIA_SMELL_CRITICAL_ENVIRONMENT]: 'Hyposmia / smell-critical environment',
  [RespiratorMedicalEvaluationFlag.HYPOTENSION]: 'Hypotension',
  [RespiratorMedicalEvaluationFlag.IMPLANTABLE_CARDIAC_DEVICE_IDLH]: 'Implantable cardiac device / IDLH environment',
  [RespiratorMedicalEvaluationFlag.ISCHEMIC_HEART_CONDITION_C_ADDITIONAL_SX]: 'Ischemic heart condition + additional cardiac Sx',
  // [RespiratorMedicalEvaluationFlag.LASTING_COMPLICATIONS_FROM_EAR_INJURY]: 'Lasting complications from ear injury',  // Deprecated
  [RespiratorMedicalEvaluationFlag.LASTING_DEFICITS_FROM_CVA]: 'Lasting deficits from CVA',
  [RespiratorMedicalEvaluationFlag.LUNG_CANCER_NOT_IN_REMISSION_FIVE_YEARS]: 'Lung cancer not in remission >5 years',
  [RespiratorMedicalEvaluationFlag.MARKED_SX_OF_CHF]: 'Marked Sx of CHF',
  [RespiratorMedicalEvaluationFlag.MEDIUM_RISK_PTX_HX_HIGH_RISK_ENVIRONMENT]: 'Medium-risk PTX Hx / high-risk environment',
  [RespiratorMedicalEvaluationFlag.MILD_SX_OF_CHF_HIGH_RISK_ENVIRONMENT]: 'Mild Sx of CHF / high-risk environment',
  [RespiratorMedicalEvaluationFlag.MI_S_CARDIAC_STRESS_TEST]: 'MI without cardiac stress test',
  [RespiratorMedicalEvaluationFlag.MODERATE_DIFFICULTY_MOVING_HEAVY_EXERTION]: '\u2265 moderate difficulty moving / heavy exertion',
  [RespiratorMedicalEvaluationFlag.MODERATE_DYSPNEA]: 'Moderate to severe dyspnea',
  [RespiratorMedicalEvaluationFlag.MULTIPLE_ARRHYTHMIAS]: 'Multiple arrhythmias',
  [RespiratorMedicalEvaluationFlag.NO_PROVEN_SAR_USE_SINCE_LUNG_CANCER_REMISSION]: 'No proven SAR/SCBA use since lung cancer remission',
  [RespiratorMedicalEvaluationFlag.PACEMAKER_COLLISION_PRONE_ENVIRONMENT]: 'Pacemaker / collision-prone environment',
  [RespiratorMedicalEvaluationFlag.PHYSICAL_RESTRICTIONS_FOR_HTN]: 'Physical restrictions for HTN',
  [RespiratorMedicalEvaluationFlag.PHYSICAL_RESTRICTIONS_FOR_MI]: 'Physical restrictions for MI',
  [RespiratorMedicalEvaluationFlag.PLEURITIC_CHEST_PAIN]: 'Pleuritic chest pain',
  [RespiratorMedicalEvaluationFlag.POORLY_CONTROLLED_ASTHMA]: 'Poorly controlled asthma',
  [RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_ACTIVE_TB]: 'Potential active TB',
  [RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_ANXIETY_TRIGGERS_AT_WORK]: 'Possibility of anxiety triggers at work',
  [RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_DERMAL_PROBLEM_AGGRAVATORS_AT_WORK]: 'Possibility of dermal problem aggravators at work',
  [RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_DERMAL_PROBLEM_AGGRAVATORS_AT_WORK_PPE_UNAVAILABLE]: 'Possibility of dermal problem aggravators at work / unable to use PPE',
  [RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_EYE_IRRITATION_AGGRAVATORS_AT_WORK]: 'Possibility of eye irritation aggravators at work',
  [RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_HYPOGLYCEMIC_SYNCOPE_S_SAFE_REMOVAL]: 'Possibility of hypoglycemic syncope without safe removal',
  [RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_SEIZURES_S_SAFE_REMOVAL]: 'Possibility of seizures without safe removal',
  [RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_UNTREATABLE_ALLERGIES_AT_WORK]: 'Possibility of untreatable allergies at work',
  [RespiratorMedicalEvaluationFlag.RECENT_AND_MULTIPLE_PNEUMOTHORACES]: 'Recent and multiple PTX',
  [RespiratorMedicalEvaluationFlag.RECENT_AND_MULTIPLE_SEIZURES]: 'Recent and multiple seizures',
  [RespiratorMedicalEvaluationFlag.RECENT_ANGINA]: 'Recent angina',
  [RespiratorMedicalEvaluationFlag.RECENT_CORONARY_ARTERY_PROCEDURE]: 'Recent coronary artery procedure',
  [RespiratorMedicalEvaluationFlag.RECENT_CVA]: 'Recent CVA',
  [RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_ASBESTOSIS]: 'Recent hospital stay for asbestosis',
  [RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_ASTHMA]: 'Recent hospital stay for asthma',
  [RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_CARDIAC_PROBLEM]: 'Recent hospital stay for cardiac problem',
  [RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_CHRONIC_BRONCHITIS]: 'Recent hospital stay for chronic bronchitis',
  [RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_DYSPNEA]: 'Recent hospital stay for dyspnea',
  [RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_EMPHYSEMA]: 'Recent hospital stay for emphysema',
  [RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_SILICOSIS]: 'Recent hospital stay for silicosis',
  [RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_WHEEZING]: 'Recent hospital stay for wheezing',
  [RespiratorMedicalEvaluationFlag.RECENT_MI]: 'Recent MI',
  [RespiratorMedicalEvaluationFlag.RECENT_SEIZURE_HIGH_RISK_ENVIRONMENT]: 'Recent seizure / high-risk environment',
  [RespiratorMedicalEvaluationFlag.RECENT_UNEXPLAINED_PTX]: 'Recent unexplained PTX',
  [RespiratorMedicalEvaluationFlag.RECENT_USE_OF_MEDS_FOR_ACUTE_ANGINA]: 'Recent use of meds for acute angina',
  [RespiratorMedicalEvaluationFlag.SEIZURE_MEDS_S_DX]: 'Seizure meds without Dx',
  [RespiratorMedicalEvaluationFlag.SERIOUS_HEART_MURMUR]: 'Serious heart murmur',
  [RespiratorMedicalEvaluationFlag.SEVERE_ASBESTOSIS]: 'Severe asbestosis',
  [RespiratorMedicalEvaluationFlag.SEVERE_ASTHMA]: 'Severe asthma',
  [RespiratorMedicalEvaluationFlag.SEVERE_CHRONIC_BRONCHITIS]: 'Severe chronic bronchitis',
  [RespiratorMedicalEvaluationFlag.SEVERE_COUGH]: 'Severe cough',
  [RespiratorMedicalEvaluationFlag.SEVERE_COUGHING_FITS]: 'Severe coughing fits',
  [RespiratorMedicalEvaluationFlag.SEVERE_DIFFICULTY_MOVING_SAR]: 'Severe difficulty moving / SAR/SCBA',
  [RespiratorMedicalEvaluationFlag.SEVERE_DYSPNEA]: 'Severe dyspnea',
  [RespiratorMedicalEvaluationFlag.SEVERE_EMPHYSEMA]: 'Severe emphysema',
  [RespiratorMedicalEvaluationFlag.SEVERE_HTN]: 'Severe HTN',
  [RespiratorMedicalEvaluationFlag.SEVERE_SILICOSIS]: 'Severe silicosis',
  [RespiratorMedicalEvaluationFlag.SEVERE_WHEEZING]: 'Severe wheezing',
  [RespiratorMedicalEvaluationFlag.SILICOSIS_RESCUE_MEDS_INACCESSIBLE_AT_WORK]: 'Silicosis rescue meds inaccessible at work',
  [RespiratorMedicalEvaluationFlag.SILICOSIS_SX_DURING_ADL]: 'Silicosis Sx during ADL',
  [RespiratorMedicalEvaluationFlag.SMOKER_C_MEDIUM_RISK_PTX_HX]: 'Smoker w/medium-risk PTX Hx',
  [RespiratorMedicalEvaluationFlag.UNASSESSED_ANGINA]: 'Unassessed angina',
  [RespiratorMedicalEvaluationFlag.UNCONTROLLED_ANGINA]: 'Uncontrolled angina',
  [RespiratorMedicalEvaluationFlag.UNCONTROLLED_SEIZURES]: 'Uncontrolled seizures',
  [RespiratorMedicalEvaluationFlag.UNMONITORED_HTN]: 'Unmonitored HTN',
  [RespiratorMedicalEvaluationFlag.UNRESOLVED_BACK_INJURY_SAR]: 'Unresolved back injury + SAR/SCBA',
  [RespiratorMedicalEvaluationFlag.UNRESOLVED_CHEST_INJURY_OR_SURGERY_C_DYSPNEA]: 'Unresolved chest injury or surgery + dyspnea',
  [RespiratorMedicalEvaluationFlag.UNRESOLVED_PNEUMONIA]: 'Unresolved pneumonia',
  [RespiratorMedicalEvaluationFlag.UNRESOLVED_RIB_FX_C_DYSPNEA]: 'Unresolved rib Fx + dyspnea',
  [RespiratorMedicalEvaluationFlag.UNRESOLVED_TB]: 'Unresolved TB',
  [RespiratorMedicalEvaluationFlag.UNSPECIFIED_HEARING_PROBLEM]: 'Unspecified hearing condition',
  [RespiratorMedicalEvaluationFlag.UNSPECIFIED_HEART_CONDITION]: 'Unspecified heart condition',
  [RespiratorMedicalEvaluationFlag.UNSPECIFIED_HEART_SX]: 'Unspecified heart Sx',
  [RespiratorMedicalEvaluationFlag.UNSPECIFIED_IMPLANTABLE_CARDIAC_DEVICE]: 'Unspecified implantable cardiac device',
  [RespiratorMedicalEvaluationFlag.UNSPECIFIED_LUNG_CONDITION]: 'Unspecified lung condition',
  [RespiratorMedicalEvaluationFlag.UNSPECIFIED_LUNG_SX]: 'Unspecified lung Sx',
  [RespiratorMedicalEvaluationFlag.UNSPECIFIED_MUSCULOSKELETAL_PROBLEM]: 'Unspecified musculoskeletal problem',
  [RespiratorMedicalEvaluationFlag.UNSPECIFIED_PROBLEM_IN_RESPIRATOR]: 'Unspecified problem in respirator',
  [RespiratorMedicalEvaluationFlag.UNSPECIFIED_VISION_PROBLEM]: 'Unspecified vision problem',
  [RespiratorMedicalEvaluationFlag.UNSTABLE_ANGINA]: 'Unstable angina',
  [RespiratorMedicalEvaluationFlag.UNSTABLE_ASBESTOSIS]: 'Unstable asbestosis',
  [RespiratorMedicalEvaluationFlag.UNSTABLE_ASTHMA]: 'Unstable asthma',
  [RespiratorMedicalEvaluationFlag.UNSTABLE_CHRONIC_BRONCHITIS]: 'Unstable chronic bronchitis',
  [RespiratorMedicalEvaluationFlag.UNSTABLE_DYSPNEA]: 'Unstable dyspnea',
  [RespiratorMedicalEvaluationFlag.UNSTABLE_EMPHYSEMA]: 'Unstable emphysema',
  [RespiratorMedicalEvaluationFlag.UNSTABLE_SILICOSIS]: 'Unstable silicosis',
  [RespiratorMedicalEvaluationFlag.UNSTABLE_WEAKNESS]: 'Unstable weakness',
  [RespiratorMedicalEvaluationFlag.UNSTABLE_WHEEZING]: 'Unstable wheezing',
  [RespiratorMedicalEvaluationFlag.VISION_CORRECTION_NOT_USABLE_AT_WORK]: 'Vision correction not usable at work',
  [RespiratorMedicalEvaluationFlag.VISION_LOSS_IMPACTING_WORK]: 'Vision loss impacting work',
  [RespiratorMedicalEvaluationFlag.WEAKNESS_IMPACTING_SAFE_REMOVAL]: 'Weakness impacting safe removal',
  [RespiratorMedicalEvaluationFlag.WEAKNESS_IMPACTING_WORK]: 'Weakness impacting work',
  [RespiratorMedicalEvaluationFlag.WHEEZING_AGGRAVATORS_AT_WORK]: 'Wheezing aggravators at work',
  [RespiratorMedicalEvaluationFlag.WHEEZING_IMPACTING_WORK]: 'Wheezing impacting work',
  [RespiratorMedicalEvaluationFlag.WHEEZING_RESCUE_MEDS_INACCESSIBLE_AT_WORK]: 'Wheezing rescue meds inaccessible at work',
  [RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_ANGINA]: 'Work accommodations for angina',
  [RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_ARRHYTHMIA]: 'Work accommodations for arrhythmia',
  [RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_ASBESTOSIS]: 'Work accommodations for asbestosis',
  [RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_ASTHMA]: 'Work accommodations for asthma',
  [RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_CHF]: 'Work accommodations for CHF',
  [RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_CHRONIC_BRONCHITIS]: 'Work accommodations for chronic bronchitis',
  [RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_CVA]: 'Work accommodations for CVA',
  [RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_DIABETES]: 'Work accommodations for DM',
  [RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_DYSPNEA]: 'Work accommodations for dyspnea',
  [RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_EMPHYSEMA]: 'Work accommodations for emphysema',
  [RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_LUNG_CANCER]: 'Work accommodations for lung cancer',
  [RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_MI]: 'Work accommodations for MI',
  [RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_SEIZURES]: 'Work accommodations for seizures',
  [RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_SILICOSIS]: 'Work accommodations for silicosis',
  [RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_WEAKNESS]: 'Work accomodations for weakness',
  [RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_WHEEZING]: 'Work accommodations for wheezing',
  [RespiratorMedicalEvaluationFlag.WORK_ALONE_C_SEIZURES]: 'Work alone + seizures',
  // [RespiratorMedicalEvaluationFlag.WORK_IN_CONFINED_SPACES_C_CLAUSTROPHOBIA]: 'Work in confined spaces + claustrophobia',  // Deprecated
};
