import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function EarInjuriesBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.brokenEardrum === null && responses.miscEarInjuries === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.brokenEardrum === YesNo.YES || responses.miscEarInjuries === YesNo.YES} title="Ear Injuries">
      <YesNoQuestion
        response={responses.brokenEardrum}
        text="Have you ever had a broken eardrum?"
      />
      <YesNoQuestion
        response={responses.brokenEardrumResolved}
        text="Has your broken eardrum fully healed or been surgically repaired?"
      />
      <YesNoQuestion
        response={responses.miscEarInjuries}
        text="Have you ever had any injury to your ears other than a broken eardrum?"
      />
      {responses.hearingLoss !== YesNo.YES && (
        <YesNoQuestion
          highlight={flags.includes(RespiratorMedicalEvaluationFlag.HEARING_LOSS_SOUND_CRITICAL_ENVIRONMENT)}
          response={responses.hearingLossCanPerformWorkSafely}
          text="Can you hear well enough to do your job safely, talk to coworkers, and hear any sounds that warn you of danger?"
        />
      )}
    </QuestionSection>
  );
}
